<div class="mat-typography">
  <h1 mat-dialog-title >Support Services Temporarily Unavailable.</h1>
  <p>
    Please note that our support service will be unavailable from <b>20 December 2024</b> to <b>13 January 2025</b>, due to our Christmas shutdown period.
    During this time, you may still submit applications and support tickets; however, responses and payments will be delayed until we resume operations.
  </p>
  <p>
    If you need assistance, we encourage you to contact us before or after this period, and we will be happy to help.<br><br>
    Thank you and have a wonderful holiday season!
  </p>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-button mat-dialog-close>Close</button>
  </div>